import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ToastModule } from 'primeng/toast';
import { UserService } from './services/user.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { environment } from '../environments/environment';
import { HealthService } from './services/health.service';
import { TooltipModule } from 'primeng/tooltip';
import { firstValueFrom } from 'rxjs';
import { WorkspaceService } from './services/workspace.service';
import { AppService } from './services/app.service';
import { WhiteLabelConfig } from 'voxr-shared';
import { SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, CommonModule, ToastModule, TooltipModule],
  providers: [],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  private app = inject(AppService);
  private userService = inject(UserService);
  private workspaceService = inject(WorkspaceService);
  private healthService = inject(HealthService);

  loading = true;
  deployment = environment.deployment;
  serverId?: Object;
  brand?: WhiteLabelConfig & { resellerId: string; };
  brandCss?: SafeHtml;

  constructor() {
    this.checkImpersonate();
    this.userService.user$.pipe(takeUntilDestroyed())
      .subscribe(async user => {
        if (user) {
          await firstValueFrom(this.workspaceService.workspaces$);
        }
        document.querySelector('body')?.classList.add('uw-ready');
        this.loading = false;
      });
  }

  private checkImpersonate(userRoles?: string[] | undefined) {
    if (!location.search.includes('impersonate=')) return;
    localStorage.setItem('impersonate', location.search.split('impersonate=')[1].split('&')[0]);
    // if (!userRoles?.includes('super-admin')) this.appService.logout(true);
    setTimeout(() => location.search = '');
  }

  async ngOnInit() {
    this.brand = await this.app.reseller();
    if(this.brand?.ui?.['design-tokens']) 
      this.brandCss = this.app.safeCss(`:root{${this.brand.ui['design-tokens']}}`);

    this.serverId = await this.healthService.serverId();

  }


}
